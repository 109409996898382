import React, { useEffect, useState } from "react";
import { RenderPageTemplate, ExtensionHandlers } from "../mapping";
import { PersonnelApi, ApiLoaderComponent } from "@unity/components";

export default function PersonnelEdit({ route, context, history }) {
  const [data, setData] = useState(false);
  const [roles, setRoles] = useState(false);
  const [user, setUser] = useState(false);
  const [manager, setManager] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnelUpdateSetup(route.match.params.id);
    setData(res.data.user);
    setRoles(res.data.roles);

    if(res.data.user.manager_id === null) {return setManager("No Manager Assigned");}
    if(!res.data.user.manager_id) {return setManager("Failed to fetch manager. Please Refresh - 500");}

    const res2 = await PersonnelApi.getPersonnelSingle(res.data.user.manager_id);
    if(res2.data == undefined 
      || res2.data == null
      || res2.data.name == undefined) return setManager("Failed to fetch manager. Please Refresh - 404");

    setManager(res2.data.name);
  };

  const handleExtensionChange = (name, value) => {
    let newData = {... data};
    newData[name] = value;
    setData(newData);
  }

  const handleSubmit = async () => {
    setLoading({
      status: true,
      data: "Saving your record, Please Wait....",
    });

    const x = await PersonnelApi.updatePersonnel(data, route.match.params.id);

    if (x.statusCode == 460) {
      // NOT validated. NOT modified, can re-display form with errors.
      setLoading({ status: true, data: "Validation Errors! Please Wait...." });
      setErrors(x.errors);
    }

    if (x.statusCode == 463) {
      // NOT successful update. IS modified, but can re-display form with errors.
      setLoading({
        status: true,
        data: "Form partially saved, but there was an Error!, Please Wait....",
      });
    }

    if (x.statusCode == 200 || x.statusCode == 201) {
      // 461 is for successful update. IS modified, display NEW form with errors.
      // Submit the form extensions too
      let error = null;
      let handlers = ExtensionHandlers(context.auth.access);
      for(let i = 0; i < handlers.length; i++) {
          const res = await handlers[i](data);
          if(!res.success) error = res.name;
      }

      if(error !== null)
      {
        setLoading({ status: true, data: `Saved Personnel, Failed to Save ${error} Sub Form. Please Review the Form and Try Again` });
      }
      else
      {
        setLoading({ status: true, data: "Save Success!, Please Wait...." });
      }
    }

    setTimeout(() => {
      setLoading({ status: false });
    }, 2000);
  };

  const handleDeregister = async () => {
    setLoading({
      status: true,
      data: "Updating your record, Please Wait....",
    });

    await PersonnelApi.deletePersonnel(data, props.match.params.id);

    setLoading({
      status: true,
      data: "Deregistration Success, Please Wait....",
    });

    setTimeout(() => {
      history.push("/personnel");
    }, 2000);
  };

  const handleContactChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  //places data into a contacts object and user object


  const handleRoleChange = (id) => {
    const newData = { ...data };
    const roleObj = roles.find((e) => e.id === id);

    newData["role_id"] = id;
    newData["role"] = roleObj.name;
    setData(newData);

  };

  useEffect(() => {
    getPersonnelData();
  }, []);

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "edit");


  if (data && roles) {
    return (
      <div>
        <ApiLoaderComponent status={loading.status} data={loading.data} />
        <RenderPage
          type="edit"
          user={user}
          roles={roles}
          data={data}
          errors={errors}
          handleContactChange={handleContactChange}
          handleRoleChange={handleRoleChange}
          handleDeregister={handleDeregister}
          handleExtensionChange={handleExtensionChange}
          route={route}
          history={history}
          context={context}
          handleSubmit={handleSubmit}
          manager={manager}
        />
      </div>
    );
  } else {
    return <p> Loading </p>;
  }
}
