import React from 'react';
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Link } from 'react-router-dom';
import PersonnelForm from '../../modules/personnel/PersonnelForm';

export default function PageTemplateStandardEdit(props) {
    return (
        <>
            <AppHeaderComponent
                name={"personnel"}
                subpage={props.data.client_name}
                subpage2="edit"
                theme={props.context.theme}
                context={props.context}
                saveBtn={props.handleSubmit}
                addNew={() => <Link to={"/personnel/create"}>
                    <AppButtonComponent
                        text="Add New"
                        btnIcon="add"
                        stylename="primary"
                        style={
                            {
                                background: props.context.theme.top_menu.button_background,
                                color: props.context.theme.top_menu.button_font,
                            }
                        }
                    />
                </Link>}

            />
            <div>
                <PersonnelForm
                    {...props}
                />


            </div>
        </>
    )
}