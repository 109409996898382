import React, { useState } from 'react';
import { AppHeaderComponent, AppButtonComponent, DataTableComponent } from "@unity/components";
import { Link } from 'react-router-dom';

export default function PageTemplateStandardIndex(props) {
    const [searchText, setSearchText] = useState("");

    return (
        <>
            <AppHeaderComponent
                name={"personnel"}
                theme={props.context.theme}
                history={props.history}
                context={props.context}
                addNew={() => <Link to={"/personnel/create"}>
                    <AppButtonComponent
                        text="Add New"
                        btnIcon="add"
                        stylename="primary"
                        style={
                            {
                                background: props.context.theme.top_menu.button_background,
                                color: props.context.theme.top_menu.button_font,
                            }
                        }
                    />
                </Link>}

            />
            <div style={{ marginTop: 160 }}>
                <DataTableComponent columns={props.columns} data={props.data} options={props.options} searchText={searchText} setSearchText={setSearchText}/>
            </div>
        </>
    )
}