import {AttendanceApi} from "@unity/components";

export default async function(data) {
    let res = await AttendanceApi.updateUserSetup(data.contact_id, data);
    if( res.success )
    {
        return {"success": true, name: "attend"}
    }
    else
    {
        return {"success": false, name: "attend"}
    }
}